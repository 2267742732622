body {
  font-family: 'Montserrat' !important;
  background-color: #010418;
  /*
  background-image: url(./assets/images/backvatars.jpg);
  */
}
@font-face {
  font-family: Ailerons;
  src: url('./assets/font/Ailerons.otf');
}

.App {
  background-color: transparent;
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  color: white;
  font-family: 'Montserrat' !important;
  text-align: center;
  padding: 10px;
}

h3 {
  font-family: 'Montserrat' !important;
  font-size: 36px;
  margin-top: 0px;
  margin-bottom: 0px !important;
}

.description {
  font-family: 'Montserrat' !important;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.description.small {
  font-size: 16px;
}

.App-link {
  color: #61dafb;
  font-family: 'Montserrat';
  font-size: 20px;
}

.logo {
  max-height: 110px;
  border-radius: 0%;
  margin-bottom: 40px;
}

.layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.walletButton {
  padding: 15px 40px 15px 40px;
  background-image: radial-gradient(circle farthest-corner at 50% 50%,#36a9e0 15%,#28225c) !important;
  border-radius: 10px;
  box-shadow: 0 0 20px 4px #f2f3f5;
  font-size: 19px;
  font-family: 'Montserrat' !important;
  font-weight: 600;
  color: white;
  margin: 20px 0;
}

body iframe {
  display: none !important;
}

.linkM {
  color: #f2f3f5;
}

.countdown {
  font-family: 'Ailerons', 'Montserrat';
  font-weight: 600;
  font-size: 110px;
  letter-spacing: -12px;
}

.countdown.small{
  font-size: 68px;
  letter-spacing: -8px;
}



@media only screen and (max-width: 600px) {
  h3 {
    font-size: 30px;
  }

  .description {
    font-size: 20px;
  }

  .footer {
    position: static !important;
    padding-top: 30px;
    padding-bottom: 20px;
    max-width: 600px;
    font-size: 12px;
  }

  .description.small {
    font-size: 14px;
  }
}

.footer {
  padding-bottom: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  color: white;
  font-family: 'Montserrat' !important;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.footer a {
  color: #9c9c9c;
}

.timeDesc {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 16px;
}

.status {
  font-weight: 600;
  font-size: 24px;
  margin: 50px 0;
}

.status .error {
  color: red;
}

.status .success {
  color: green;
}

.card {
  background-color: #000519;
  padding: 0;
  border: 1px solid #36a9e0;
}

.card-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.card-subtitle {
  font-weight: 400;
  font-size: 18px;
}

.row {
  max-width: 1400px;
}

.supply {
  font-family: 'Ailerons', 'Montserrat';
  font-size: 56px;
  letter-spacing: -10px;
  margin-bottom: 0;
  height: 1;
}

.collection {
  margin-bottom: 20px;
}

.selectorAmount {
  margin: 0 20px;
  color: white;
  border : 1px solid white;
  font-weight: 800;
  font-size: 20px;
  background-color: #28225c;
}

.selectorAmount:hover {
  background-color: #36a9e0;
}

.selectorAmount:focus {
  background-color: #36a9e0;
}

.selectorAmount:active {
  background-color: #36a9e0;
}

.goWrong {
  margin-top: 40px;
}

.listingDetails {
  text-align: left !important;
}

.listingDetails .card-title {
  margin-bottom: 0;
}

.listingDetails .card-subtitle {
  margin-bottom: 15px;
}

.listingDetails .card-subtitle:last-of-type {
  margin-bottom: 0px;
}

.subSupply {
  font-size: 20px;
}

.promo {
  text-decoration: line-through;
}

.row.full {
  max-width: 100%;
}

.social {
  position: absolute;
  top: 20px;
  right: 30px;
}

.social a {
  margin: 0 10px;
}

.countdown.light {
  font-size: 65px !important;
  letter-spacing: 0px;
}